import { TYPE_SEARCH } from '@/modules/b2b/constants';
import { ACTIONS } from '@/modules/b2b/store/product/actions';
import {
  DEFAULT_SORT_ALL_KEY,
  DEFAULT_SORT_HISTORY_KEY,
  MODULES_FILTER,
  MUTATIONS,
  OFFSET_LIST_DEFAULT
} from '@/modules/shared/constants/';
import { useLoading } from '@/modules/shared/utils/';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { SORTING_DATA } from './data';

export const getCategoryListSort = (categoryName = 'all') => {
  return SORTING_DATA.categoryList[categoryName];
};
export const isCategoryHistory = (categoryName) => (categoryName === 'history' ? 'history' : 'all');
export const getItemByKey = ({ categoryName = 'all', key }) => {
  return getCategoryListSort(isCategoryHistory(categoryName)).find((value) => value.key === key);
};

export const getDefaultItemByCategory = (categoryName) =>
  categoryName === 'history' ? DEFAULT_SORT_HISTORY_KEY : DEFAULT_SORT_ALL_KEY;

export const useModalSort = ({ defaultSortItemKey, storeAddress, tabCategory }) => {
  const { getters, dispatch, commit } = useStore();
  const getCategory = getters[`${storeAddress}/list`].category;
  const categoryName = isCategoryHistory(tabCategory === 'history' ? tabCategory : getCategory);
  const changeSortItemKey = ref(defaultSortItemKey);
  const { showLoading } = useLoading();
  const applyFilter = async () => {
    const itemSort = getItemByKey({ categoryName, key: changeSortItemKey.value });
    return showLoading(() =>
      dispatch(`${storeAddress}/${ACTIONS.UPDATE_SEARCH}`, {
        search: {
          sortBy: itemSort.sortBy,
          sortType: itemSort.sortType,
          offset: OFFSET_LIST_DEFAULT
        },
        type: TYPE_SEARCH.SORT
      }).then(() => {
        commit(`${MODULES_FILTER}/${MUTATIONS.UPDATE_FILTER}`, itemSort.key);
      })
    );
  };

  const resetFilter = () => {
    changeSortItemKey.value = getDefaultItemByCategory(categoryName);
  };
  return {
    applyFilter,
    resetFilter,
    changeSortItemKey,
    categoryName
  };
};

export const useUpdateFilter = () => {
  const { commit } = useStore();

  const updateFilter = (key) => {
    commit(`${MODULES_FILTER}/${MUTATIONS.UPDATE_FILTER}`, key);
  };
  const onSetDefaultSort = (id) => {
    if (id === 'history') {
      updateFilter(DEFAULT_SORT_HISTORY_KEY);
    } else {
      updateFilter(DEFAULT_SORT_ALL_KEY);
    }
  };
  return {
    updateFilter,
    onSetDefaultSort
  };
};
