<template>
  <ion-page :key="reloadHomePage">
    <ion-header>
      <toolbar
        :selectedCompany="selectedCompany"
        :isLoadAllProduct="isLoadAllProducts"
        :isResetSearch="isResetSearch"
        :selectedTab="changeCategory"
        :isExploreProduct="isExploreProduct"
        :currentFilter="currentFilter"
        :isOnScrollTop="isOnScrollTop"
        @resetLoadAllProduct="setLoadAllProducts(false)"
        @resetSearch="resetSearch"
        @clearPrevFilter="clearPrevFilter"
        @setIsHomePageValue="setIsHomePageValue"
        @reRenderToolbar="reFetchSelectedCompany"
      />
      <slides-cate @reset-search="resetSearch" @setExploreProduct="setExploreProduct" />
    </ion-header>
    <div class="maintenance" v-if="isComming">
      <div class="title">
        Maintenance on
        {{
          localtime.slice(0, 3) +
          ',' +
          localtime.slice(8, 10) +
          ' ' +
          localtime.slice(4, 7) +
          ',' +
          localtime.slice(16, 21) +
          ' ' +
          `(${localtime.slice(25, 31)})`
        }}
      </div>
      <div class="time">{{ `${hours}:${minutes}:${seconds}s` }}</div>
    </div>
    <invoice :key="new Date()" />
    <ion-content
      ref="contentRef"
      :scroll-events="true"
      @ionScroll="handleScroll($event)"
      class="default-background"
    >
      <product-list
        @fetchAllProduct="setLoadAllProducts(true)"
        :changeCategory="changeCategory"
        :selectedNewCustomer="selectedCustomer"
        @load-data="loadData"
        @reloadHomePage="reloadHomePage"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { getItemByKey, useUpdateFilter } from '@/components/sort/composable';
import { TYPE_SEARCH, defaultSize } from '@/modules/sale/constants';
import { ACTIONS } from '@/modules/sale/store/product/actions';
import { MUTATIONS } from '@/modules/sale/store/product/mutations';
import { GETTER, MODULES_FILTER, SORTBY_FILTER_NAME, TAB_CATEGORY } from '@/modules/shared/constants';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
// import meta from '../../../../../package.json';
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import cleverTap from '@/services/shared/helper/clevertap.js';
import debounce from 'lodash.debounce';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('sale/product');
const { mapGetters: mapGettersMaintenance } = createNamespacedHelpers('b2b/version');
const { mapGetters: mapGettersCustomer } = createNamespacedHelpers('sale/customer');
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('sale/cart');
const { mapGetters: mapGettersExploreTabCategory } = createNamespacedHelpers('sale/category');

export default defineComponent({
  name: 'HomeSale',
  components: {
    Invoice: defineAsyncComponent(() => import('./components/OverdueNotif.vue')),
    Toolbar: defineAsyncComponent(() => import('./components/Toolbar.vue')),
    SlidesCate: defineAsyncComponent(() => import('./components/SlidesCate.vue')),
    ProductList: defineAsyncComponent(() => import('./components/ProductList.vue'))
  },
  inject: ['$storage'],

  setup() {
    const noProductIsLoaded = ref(false);
    const { onSetDefaultSort } = useUpdateFilter();
    const prevHistoryPath = ref('');
    const selectedCompany = ref(null);
    const isOnScrollTop = ref(false);
    const isLoadAllProducts = ref(false);
    const reloadHomePage = ref(1);
    const setLoadAllProducts = (state) => (isLoadAllProducts.value = state);
    return {
      noProductIsLoaded,
      onSetDefaultSort,
      prevHistoryPath,
      selectedCompany,
      isOnScrollTop,
      setLoadAllProducts,
      isLoadAllProducts,
      reloadHomePage,
      handleScroll: debounce((e) => {
        const onScrollHeightValue = e.detail.scrollTop;
        if (onScrollHeightValue > 10) isOnScrollTop.value = true;
        if (onScrollHeightValue === 0) isOnScrollTop.value = false;
      }, 200)
    };
  },
  data() {
    return {
      isResetSearch: null,
      hours: '',
      minutes: '',
      seconds: '',
      isComming: false,
      localtime: '',
      changeCategory: TAB_CATEGORY.HISTORY,
      selectedTab: '',
      prevCate: '',
      isExploreProduct: false,
      currentFilter: '',
      isHomePage: false
    };
  },

  async ionViewWillEnter() {
    const historyState = window.history.state;
    this.prevHistoryPath = historyState?.back;

    if (this.prevHistoryPath === '/sale/search-products' || this.prevHistoryPath === '/sale/cart') {
      await this[MUTATIONS.RESET_CATEGORY]();
      this.selectedTab = TAB_CATEGORY.HISTORY;
      this.search.mainCategoryId = this.selectedTab;
      cleverTap.onUserSelectCategory(this.selectedTab);
      this.changeCategory = this.selectedTab;
      await this[ACTIONS.UPDATE_SEARCH]({
        search: {
          ...this.search,
          searchQueries: '',
          mainCategoryId: TAB_CATEGORY.HISTORY,
          sortBy: SORTBY_FILTER_NAME.PURCHASED,
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    }
  },

  async ionViewDidEnter() {
    const historyState = window.history.state;
    this.isHomePage = true;
    this.selectedCompany = await this.$storage.getSelectedCompany();
    this.isOnScrollTop = false;
    if (this.finishOrder) {
      this.setLoadAllProducts(true);
      await this[ACTIONS_CART.FINISH_ORDER](false);
    }
    if (this.maintenance) {
      var date = new Date(this.maintenance?.start_time);
      this.localtime = date.toString();
      this.maintenance?.time_is_coming
        ? (this.countdownTimeStart(this.maintenance.time_is_coming), (this.isComming = true))
        : (this.isComming = false);
    }
    let getTabName = new URLSearchParams(historyState.current?.split('?')[1]);
    let cate = getTabName.get('cate');
    let prevPath = historyState?.back;
    this.prevCate = this.exploreRelatedCategory;
    const basePath = prevPath?.split('/').slice(0, 4)?.join('/');
    if (
      (!historyState.forward && historyState.forward !== null) ||
      historyState.forward?.indexOf('product-detail') === -1
    ) {
      this.resetSearch({
        cate: cate ? cate : TAB_CATEGORY.HISTORY,
        checkCate: (cate ? cate : TAB_CATEGORY.HISTORY) + '-'
      });
    } else if (historyState.forward === null && basePath === '/sale/main/product-detail') {
      this.isExploreProduct = true;
      this.currentFilter = SORTBY_FILTER_NAME.POPULAR;
      this.resetSearch({
        cate: this.prevCate,
        checkCate: this.prevCate + '-'
      });
    }
    if (this.$router.currentRoute._rawValue.query.filter) {
      this.changeCategory = this.$router.currentRoute._rawValue.query.filter;
    }
    cleverTap.recordEventWithName('Browsing Products Started');
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/sale/search-products') {
      this.setIsHomePageValue();
    }
    next();
  },
  computed: {
    ...mapGettersMaintenance(['maintenance']),
    ...mapGetters(['loading', 'list', 'search', 'isChangeAllCategory']),
    ...mapGettersCart(['finishOrder']),
    ...mapGettersCustomer(['selectedCustomer']),
    ...mapGettersExploreTabCategory(['exploreRelatedCategory']),
    isShowInfinityScroll() {
      if (this.loading) return true;
      return this.list.items.length < this.list.totalCount;
    }
  },
  watch: {
    isChangeAllCategory() {
      this.setLoadAllProducts(true);
      this.changeCategory = this.selectedTab;
    },
    loading(value) {
      // move to all only on home page and will not triggered on other page
      if (!value && this.list?.totalCount <= 0 && this.search.mainCategoryId !== TAB_CATEGORY.ALL) {
        this.moveToAll();
      }
    },
    selectedCustomer: {
      async handler(newCompany, oldCompany) {
        if (oldCompany && newCompany && newCompany.id !== oldCompany.id) {
          this.changeCategory = TAB_CATEGORY.HISTORY;
          this.resetSearch({
            cate: TAB_CATEGORY.HISTORY,
            checkCate: TAB_CATEGORY.HISTORY + '-'
          });
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_SEARCH]),
    ...mapActionsCart([ACTIONS_CART.FINISH_ORDER]),
    ...mapMutations([MUTATIONS.RESET_CATEGORY]),
    async reFetchSelectedCompany() {
      this.selectedCompany = await this.$storage.getSelectedCompany();
    },
    countdownTimeStart(params) {
      this.countDownDate = new Date().getTime() + Number(params);
      const data = setInterval(() => {
        if (this.$router.currentRoute._value.href === '/sale/main/home') {
          var now = new Date().getTime();
          const distance = Number(this.countDownDate) - Number(now);
          this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        } else {
          clearInterval(data);
          return;
        }
      }, 1000);
    },
    resetSearch(value) {
      this.search.searchQueries = '';
      cleverTap.onUserSelectCategory(value.cate);
      this.isResetSearch = value;
      this.selectedTab = value.cate;
      this.changeCategory = this.selectedTab;
    },
    clearPrevFilter() {
      this.currentFilter = '';
    },
    setExploreProduct() {
      this.isExploreProduct = false;
    },
    async moveToAll() {
      const category = TAB_CATEGORY.ALL;
      this.changeCategory = TAB_CATEGORY.ALL;
      this.onSetDefaultSort(category);
      const sortItemKey = this.$store.getters[`${MODULES_FILTER}/${GETTER.GET_FILTER}`];
      const itemSort = getItemByKey({ categoryName: category, key: sortItemKey });

      const searchOptions = {
        sortBy: itemSort.sortBy,
        sortType: itemSort.sortType,
        mainCategoryId: category
      };
      if (this.search.mainCategoryId === category) {
        searchOptions.searchQueries = '';
      }

      await this[ACTIONS.UPDATE_SEARCH]({
        search: searchOptions,
        type: TYPE_SEARCH.CATEGORY
      });
    },
    setIsHomePageValue() {
      this.isHomePage = false;
    },
    async loadData(e) {
      try {
        if (this.noProductIsLoaded) return;

        this.noProductIsLoaded = true;

        if (!this.loading) {
          let offsetSize = this.search.offset;
          const length = this.list.items.length;
          if (length === this.list.totalCount || this.offsetSize >= this.list.totalCount) {
            e.target.disabled = true;
            offsetSize = 0;
          } else {
            offsetSize += defaultSize;

            const sortItemKey = this.$store.getters[`${MODULES_FILTER}/${GETTER.GET_FILTER}`];
            const itemSort = getItemByKey({ categoryName: this.list.category, key: sortItemKey });

            await this[ACTIONS.UPDATE_SEARCH]({
              search: {
                sortType: this.changeCategory === TAB_CATEGORY.HISTORY ? 'desc' : itemSort?.sortType,
                sortBy: this.isExploreProduct
                  ? SORTBY_FILTER_NAME.POPULAR
                  : this.changeCategory === TAB_CATEGORY.HISTORY
                  ? SORTBY_FILTER_NAME.PURCHASED
                  : itemSort?.sortBy,
                offset: offsetSize
              },
              isLoadMore: true,
              type: TYPE_SEARCH.CATEGORY
            });
            e?.target.complete();
          }
        }
      } catch (error) {
        console.log(`loaddata error: ${JSON.stringify(error)}`, error);
        this.noProductIsLoaded = true;
      } finally {
        this.noProductIsLoaded = false;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
ion-header {
  background-color: white;
}
.default-background {
  --background: #f5f5f5;
}
.maintenance {
  width: 100%;
  background: #fdc40c0f;
  padding: 12px 20px;
  & > .title {
    font-size: 14px;
    list-style: 14px;
    font-weight: 700;
    color: #181818;
  }
  & > .time {
    font-size: 14px;
  }
}
</style>
